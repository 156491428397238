<template>
    <div class="main">
        <div class="fyy-source">
            <div class="fyy-index-title">
            <h5>营收统计</h5>
            <el-button  @click="handleAdd">申请提现</el-button>
            </div>
            <el-row :gutter="20">
            <el-col :span="6">
                <div class="grid-content bg-purple-ora"> <img src="../../assets/merchantClient/img/index01.png"/>
                <div class="fyy-toptxt">
                    <h5>总收益金额</h5>
                    <h3>{{total}}</h3>
                </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple-blu"> <img src="../../assets/merchantClient/img/index02.png"/>
                <div class="fyy-toptxt">
                    <h5>可提现金额</h5>
                    <h3>{{withdrawalTotal}}</h3>
                </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple-gre"> <img src="../../assets/merchantClient/img/index03.png"/>
                <div class="fyy-toptxt">
                    <h5>即将解冻金额</h5>
                    <h3>{{soonThawTotal}}</h3>
                </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple-lue"> <img src="../../assets/merchantClient/img/index04.png"/>
                <div class="fyy-toptxt">
                    <h5>累计解冻金额</h5>
                    <h3>{{thawTotal}}</h3>
                </div>
                </div>
            </el-col>
            </el-row>
        </div>
        <div class="fyy-index-center">
            <div class="el-col el-col-12">
            <div class="fyy-source">
                <div class="fyy-index-title">
                <h5>营收简报</h5>
                <el-date-picker
                    v-model="value1"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    @change="handleRevenueBriefing">
                </el-date-picker>
                </div>
                <el-row :gutter="20">
                <el-col :span="12" class="center-txt">
                    <div class="grid-content bg-purple">
                    <p>订单笔数</p>
                    <h1>{{revenueNum}}笔</h1>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                    <p>预计收益</p>
                    <h1>{{revenueTotal}}元</h1>
                    </div>
                </el-col>
                </el-row>
            </div>
            </div>
            <div class="el-col el-col-12">
            <div class="fyy-source">
                <div class="fyy-index-title">
                <h5>商品销售统计</h5>
               
                    <el-date-picker
                        v-model="value8"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        @change="handleGoodsSalesStatistics">
                    </el-date-picker>
        
                </div>
                <el-row :gutter="20">
                    <div class="grid-content bg-purple" id="goodsSalesStatisticsDiv"> 
                        
                    </div>
                </el-row>
            </div>
            </div>
        </div>
        <div class="fyy-source fyy-index-bot">
            <div class="fyy-index-title">
            <div class="fyy-index-tab">
                <h5>营收趋势</h5>
                <div class="home-tab">
                    <el-button id="home-tab-left" class="home-tab-left" :class="[isActive?'active':'']" @click="changeIt">订单收益</el-button>
                    <el-button id="home-tab-right" class="home-tab-right" :class="[isActive?'':'active']" @click="changeIt">订单量</el-button>
                </div>
            </div>
            <el-date-picker
                v-model="value2"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="handleRevenueTrend">
            </el-date-picker>
            </div>
            <el-row :gutter="20">
            <div class="grid-content bg-purple" id="revenueTrendDiv" style="width:100%;height: 380px;">

            </div>
            </el-row>
        <!--
        替换img部分
            <el-row :gutter="20">
            <div class="grid-content bg-purple"> <img src="img/index05.png"/> </div>
            </el-row>
        -->
        </div>
        <withdrawalApplication
        v-if="showModal"
        :title="modalTitle"
        :businessKey="businessKey"
        @close="onDetailModalClose"
        ></withdrawalApplication>
    </div>
</template>
<script>
import homeApi from '@/api/merchantClient/home'
import withdrawalApplication from "./withdrawalApplication";

export default {
    name:"merchantClientHome",
    data(){
        return{
            loading: false,
            loadingText: '加载中',
            total:"",
            withdrawalTotal:"",
            soonThawTotal:"",
            thawTotal:"",
            revenueNum:"",
            revenueTotal:"",
            pieData:[],
            dayList:[],
            dataList:[],
            value1:["2020-08-01","2020-09-01"],
            value2:["2020-08-01","2020-09-01"],
            value8:["2020-08-01","2020-09-01"],
            isActive: true,
            showModal: false,
            modalTitle: "",
            totalNum:"",
            totalFee:"",
        }
    },
    methods: {
        everyDayMeasuringView() {
            let myChart = this.$echarts.init(
                document.getElementById("goodsSalesStatisticsDiv")
            );

                  // 绘制图表
            myChart.setOption({
                title: {
                    textAlign: 'center'
                },
                 tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                series: [{
                    name: '商品类别',
                    type: 'pie',
                    radius: '80%',
                    center: ['50%', '60%'],
                    data: this.pieData,
                    animation: false,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }]
            });
        },

        revenueTrendView() {
            let myChart = this.$echarts.init(
                document.getElementById("revenueTrendDiv")
            );

            // 绘制图表
            myChart.setOption({
                title: {
                    left: 'center',
                    text: '订单收益：￥'+this.totalFee+'，笔数：'+this.totalNum,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLabel: {
                        interval: 0,
                        rotate: 40
                    },
                    data: this.dayList
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: this.dataList,
                    type: 'line'
                }]
            });
        },
        loadRevenueTrendView(type){
            var formData = new FormData();
            formData.append("revenueTrendRange", this.value2);
            formData.append("type",type);

        
            homeApi.revenueTrend(formData).then((resp)=>{
                var jsonData = resp.data;
                
                if (jsonData.result) {
                    this.dayList = jsonData.data.day;
                    this.dataList = jsonData.data.data;
                    this.totalNum = jsonData.data.totalNum;
                    this.totalFee = jsonData.data.totalFee;
                    this.revenueTrendView();
                }
            });
        },
        changeIt: function() {
            this.isActive = !this.isActive;
            if(this.isActive == true){
                this.loadRevenueTrendView("1")
            }
            else{
                this.loadRevenueTrendView("2")
            }
        },
        handleRevenueTrend(val){
            this.value2=[];
            this.value2.push(val[0]);
            this.value2.push(val[1]);
            this.changeIt();
        },
        handleGoodsSalesStatistics(val){
            this.value8=[];
            this.value8.push(val[0]);
            this.value8.push(val[1]);

            var formData2 = new FormData();

            formData2.append("goodsSalesStatisticsRange", this.value8);

            homeApi.goodsSalesStatistics(formData2).then((resp)=>{
                var jsonData = resp.data;
                
                if (jsonData.result) {
                this.pieData = jsonData.data;
                this.everyDayMeasuringView();
                }
            });
        },
        handleRevenueBriefing(val){
            this.value1=[];
            this.value1.push(val[0]);
            this.value1.push(val[1]);

            var formData = new FormData();

            formData.append("revenueBriefingRange", this.value1);

            homeApi.revenueBriefing(formData).then((resp)=>{
                var jsonData = resp.data;
                
                if (jsonData.result) {
                this.revenueNum = jsonData.data.revenueNum;
                this.revenueTotal=jsonData.data.revenueTotal;
                }
            });
        },
        onDetailModalClose(refreshed) {
            //保存成功后回调
            this.showModal = false;
        },
        handleAdd() {
            this.modalTitle = "请填写提现申请单";
            this.businessKey = "";
            this.showModal = true;
        },
            
    },
    async mounted() {
        console.log("mounted");
        var self = this;

        this.loading = true;
        this.loadingText = "加载中";

        homeApi.accountInformation().then((resp)=>{
            var jsonData = resp.data;
            
            if (jsonData.result) {
               this.total = jsonData.data.total;
               this.withdrawalTotal=jsonData.data.withdrawalTotal;
               this.soonThawTotal=jsonData.data.soonThawTotal;
               this.thawTotal=jsonData.data.thawTotal;
            }
        });

        var formData = new FormData();

        formData.append("revenueBriefingRange", this.value1);

        homeApi.revenueBriefing(formData).then((resp)=>{
            var jsonData = resp.data;
            
            if (jsonData.result) {
               this.revenueNum = jsonData.data.revenueNum;
               this.revenueTotal=jsonData.data.revenueTotal;
            }
        });

        var formData2 = new FormData();

        formData2.append("goodsSalesStatisticsRange", this.value8);

        homeApi.goodsSalesStatistics(formData2).then((resp)=>{
            var jsonData = resp.data;
            
            if (jsonData.result) {
               this.pieData = jsonData.data;
               this.everyDayMeasuringView();
            }
        });
        
        this.loadRevenueTrendView("1");
        
    },
    components: {
        "withdrawalApplication": withdrawalApplication
    },
}
</script>
<style lang="scss" scoped src="../../assets/merchantClient/css/admin.scss"></style>